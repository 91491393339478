<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <el-input v-model="voice" placeholder="请输入配音师名称"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <!-- <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button> -->
            </div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">配音师列表</div>
                <el-button type="primary" id="AddAccount" @click="addbud($event)" class="yinyinga" icon="el-icon-plus">新增配音师</el-button>
                <div class="lvtotal" style="line-height: 33px;">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                </div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">

                <el-table-column prop="file_url" label="头像" width="100px">
                    <template slot-scope="scope">
                        <img :src="scope.row.file_head_portrait?scope.row.file_head_portrait.file_url:''" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="" label="配音师信息">
                    <template slot-scope="scope">
                        <div style="font-weight: 600;"> {{scope.row.mixer ? scope.row.mixer : '暂无'}}</div>
                        <div>
                            <span style="color: rgb(102, 102, 102);font-size:12px"> {{scope.row.name ? scope.row.name : '暂无'}} </span>
                            <i class="el-icon-video-play" style="color:#0075f7; cursor: pointer;" @click="onrecord(scope.row)"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sex" label="性别">
                    <template slot-scope="scope">
                        {{scope.row.sex == 1 ? '男' : '女'}}
                    </template>
                </el-table-column>
                <el-table-column prop="accent" label="口音特色">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.category" placement="top">
                            <div style="overflow-font">
                                <span v-for="(item,key) in scope.row.accent" :key="key">
                                    <span v-if="key<3"> {{item.accent}}</span>
                                </span>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="label" label="标签">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.feature" placement="top">
                            <div style="overflow-font">
                                <span v-for="(item,key) in scope.row.label" :key="key">
                                    <span v-if="key<3"> {{item.label}}</span>
                                </span>
                            </div>
                        </el-tooltip>

                    </template>
                </el-table-column>
                <el-table-column prop="online_time" label="在线时间" width="130px">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.online_time" placeholder="请输入内容" @change="online(scope.row.online_time,scope.row)"> </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="sorf" label="排序" width="80px">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sorf" placeholder="请输入内容" @change="oninput(scope.row.sorf,scope.row)"> </el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-popconfirm title="确定要禁用吗" @confirm="onForbidden(2,scope.row)">
                            <el-button size="mini" slot="reference" type="success" v-if="scope.row.status==1" style="margin-left:0">已启用</el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定要启用吗" @confirm="onEnable(1,scope.row)">
                            <el-button size="mini" slot="reference" type="danger" v-if="scope.row.status==2" style="margin-left:0">已禁用</el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定要启用吗" @confirm="onEnable(1,scope.row)">
                            <el-button size="mini" slot="reference" type="info" v-if="scope.row.status==3" style="margin-left:0">已删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="is_operating_type" label="等级">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.level==1" style="background: #07D258; padding:5px;border-radius: 5px;">{{scope.row.level==1 ? '一星 ' : scope.row.level==2 ? '二星':scope.row.level==3 ? '三星':''}}</span>
                                <span v-if="scope.row.level==2" style="background: #0075F7;  padding:5px;border-radius: 5px;">{{scope.row.level==1 ? '一星 ' : scope.row.level==2 ? '二星':scope.row.level==3 ? '三星':''}}</span>
                                <span v-if="scope.row.level==3" style="background: #E61B1B;  padding:5px;border-radius: 5px;">{{scope.row.level==1 ? '一星 ' : scope.row.level==2 ? '二星':scope.row.level==3 ? '三星':''}}</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='star(1,scope.row)'><span>一星</span></el-dropdown-item>
                                <el-dropdown-item @click.native='star(2,scope.row)'><span>二星</span></el-dropdown-item>
                                <el-dropdown-item @click.native='star(3,scope.row)'><span>三星</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="old" label="资历">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.old==1" style="background: #07D258; padding:5px;border-radius: 5px;">常驻</span>
                                <span v-if="scope.row.old==2" style="background: #0075F7;  padding:5px;border-radius: 5px;">新晋</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='qualifications(1,scope.row)'><span>常驻</span></el-dropdown-item>
                                <el-dropdown-item @click.native='qualifications(2,scope.row)'><span>新晋</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="old" label="配音师来源" width="140">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.source=='ONSTAFF'" style="background: #07D258; padding:5px;border-radius: 5px;">在职人员</span>
                                <span v-else-if="scope.row.source=='OFFSTAFF'" style="background: #0075F7;  padding:5px;border-radius: 5px;">编外人员</span>
                                <span v-else style="background: #ddd;  padding:5px;border-radius: 5px;">未指定</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native="onLyPeiyinshi('ONSTAFF',scope.row)"><span>在职人员</span></el-dropdown-item>
                                <el-dropdown-item @click.native="onLyPeiyinshi('OFFSTAFF',scope.row)"><span>编外人员</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="alipay_account" label="支付宝">
                </el-table-column>
                <el-table-column label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">编辑</el-button>
                        <el-button type="primary" class="bianji" size="mini" plain @click="onCase(scope.$index, scope.row ,$event) " style="margin-left:20px" id="ComAccount">查看案例</el-button>
                        <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
            <AddTeacher ref="AddTeacher"></AddTeacher>
            <EditTeacher ref="EditTeacher"></EditTeacher>
            <Case ref="Case"></Case>
            <!-- 弹窗 -->
            <el-dialog title="音频播放" :visible.sync="dialogFormVisible" width="400px" @close='closeDialog' class="dialog">
                <audio controls ref="audio" :src="audio" loop="loop" class="audio">

                </audio>
            </el-dialog>
        </div>
    </div>
</template>
 
<script>
import AddTeacher from "./AddTeacher.vue";
import Case from "./case.vue";
import EditTeacher from "./EditTeacher.vue";
export default {
    data () {
        return {
            paramData: {}, //表格参数
            dialogFormVisible: false,
            voice: "", //搜索内容
            tableData: [],
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            page: "1",
            activeName: "",
            PageTopCompanyList: 1, //群页码
            company_id: "", //群id
            qunlist: "", //群循环
            PageTopCorporateAccountList: 1, //企业页码
            corporate_account_id: "", //企业id
            qiyelist: "", //企业循环
            accountlist: "", //端口列表
            PagegetAdvertiserList: "", //端口页码
            teacher_id: "",
            audio: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
        };
    },
    mounted () {
        this.ongetTopAgentAccountList();
    },
    components: {
        AddTeacher,
        EditTeacher,
        Case,
    },
    methods: {
        //资历
        qualifications (val, row) {
            var param = {
                old: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerOld, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 播放录音
        onrecord (row) {
            this.audio = row.file_introduce.file_url;
            this.dialogFormVisible = true;
        },
        closeDialog () {
            this.audio = "";
            this.dialogFormVisible = false;
        },
        //搜索
        onButtonInquire () {
            this.paramData = {
                mixer: this.voice,
            };
            this.paramPage = 1;
            this.ongetTopAgentAccountList();
        },
        //排序
        oninput (val, row) {
            var param = {
                mixer_id: row.id,
                sorf: val,
            };
            this.$service.post(this.$api.setMixerSorf, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 配音在线
        online (val, row) {
            var param = {
                mixer_id: row.id,
                online_time: val,
            };
            this.$service.post(this.$api.setMixerOnlineTime, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });

        },
        //新增配音师
        addbud (e) {
            this.$refs.AddTeacher.onyunyingtan();
        },
        //查看案例
        onCase (index, row, e) {
            this.$router.push({
                path: "/ErLangCha/teacher/case",
                query: { id: row.id },
            });
            // this.$refs.Case.ontickling(row.id);
        },
        //配音师列表
        ongetTopAgentAccountList () {
            this.tableData = [];
            var category = [];  //标签
            var feature = [];  //特色
            var param = this.paramData;
            param.page = this.paramPage;
            this.$service.get(this.$api.getMixerList, param, (res) => {
                if (res.code == "200") {

                    res.data.data.forEach((v, k) => {

                        // console.log(v.accent)
                        // console.log(v.label)
                        v.accent.forEach((vs, ks) => {
                            // console.log(vs)
                            category.push(vs.accent);
                            v.category = category.toString();
                        })
                        v.label.forEach((vs, ks) => {
                            // console.log(vs)
                            feature.push(vs.label);
                            v.feature = feature.toString();
                        })

                    });

                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        //显示条数改变
        selectTrigger () {
            this.paramPage = 1;
            this.paramData.per_page = this.per_page;
            this.ongetTopAgentAccountList();
        },
        // 分页
        DialogCurrentChange (val) {
            this.paramPage = val;
            this.ongetTopAgentAccountList();
        },
        //设置星级
        star (val, row) {
            var param = {
                level: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerLevel, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 筛选
        filterTag (value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit (index, row, e) {
            this.$refs.EditTeacher.onbianjishi(row.id);
        },
        //删除
        onEnter (row) {
            var param = {
                mixer_id: row.id,
            };
            this.$service.post(this.$api.delMixer, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        //启用
        onEnable (val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        //禁用
        onForbidden (val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },


        // 修改配音师来源
        onLyPeiyinshi (val, row) {
            var param = {
                source: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerSource, param, (res) => {
                if (res.code == "200") {
                    row.source = val
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 40px;
            height: 40px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .dialog {
        margin-top: 200px;
    }
    .audio {
        height: 100px;
        padding: 40px 0px;
    }
    .sorfinput {
    }
    .overflow-font {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; /*数字就是想展示的行数，设置为1的时候就变成显示单行了*/
        -webkit-box-orient: vertical;
        word-wrap: break-word; /*允许长单词换行  单词换行,这个是关键,若是不加的话就不起做用,必须有!!!*/
    }
}
</style>
<style lang="scss">
.operation {
    // .el-input {
    //     width: 180px;
    //     margin-right: 15px;
    // }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        padding: 5px;
        position: relative;
    }
    .tag {
        cursor: pointer;
    }
    .el-dialog__header {
        background: #0075f7 !important;
    }
    .el-dialog__title {
        color: #fff;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
}
</style>