<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="新增配音师" :visible.sync="yunyingtan" :closeOnClickModal='false' width="700px" @close="closeDialog()">
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师昵称:
                        </div>
                        <el-input v-model="form.mixer" autocomplete="off" placeholder="请输入昵称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师姓名:
                        </div>
                        <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth" class="pitem">
                        <div class="kuai">
                            <span class="xing">* </span>
                            头像:
                        </div>
                        <el-upload class="upload-demo" ref='upload' :action="$api.uploadFile + '?token=' + token" list-type="picture-card" :on-success="onSuccessupload" :on-remove="handleRemoves">
                            <i class="el-icon-plus"></i>
                        </el-upload>

                        <!-- <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog> -->
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师性别:
                        </div>
                        <template>
                            <el-radio v-model="form.sex" label="1">男</el-radio>
                            <el-radio v-model="form.sex" label="2">女</el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师特色:
                        </div>
                        <el-input v-model="form.feature" autocomplete="off" placeholder="请输入特色"></el-input>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音上传:
                        </div>
                        <el-upload class="upload-demo" ref='uploadbud' :action="$api.uploadFile + '?token=' + token" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed" :on-success="onSuccessbud" :file-list="fileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师标签:
                        </div>
                        <template>
                            <el-checkbox-group v-model="form.label" @change="onShopCheChange">
                                <el-checkbox v-for="(item,key) in checkList" :key="key" :label="item.id">{{item.label}}</el-checkbox>
                            </el-checkbox-group>
                        </template>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            配音师方言:
                        </div>
                        <template>
                            <el-checkbox-group v-model="form.accent" @change="onlocalismChange">
                                <el-checkbox v-for="(item,key) in localismList" :key="key" :label="item.id">{{item.accent}}</el-checkbox>
                            </el-checkbox-group>
                        </template>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            自我简介:
                        </div>
                        <el-input v-model="form.self" autocomplete="off" placeholder="请输入简介"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            支付宝:
                        </div>
                        <el-input v-model="form.alipay_account  " autocomplete="off" placeholder="请输入支付宝"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            上传视频:
                        </div>
                        <el-button size="small" type="primary" @click="uploadVideo">点击上传</el-button>
                        <span v-if="SourceList.length" style="margin-left:20px">新上传{{SourceList.length}}条视频</span>
                    </el-form-item> -->
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- <VideoSourceDialog ref="VideoSourceDialog" @onSourceList="onSourceList"></VideoSourceDialog> -->
    </div>
</template>

<script>
import VideoSourceDialog from "./videoSourceDialog.vue";
export default {
    data () {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            form: {
                //表单信息
                feature: "", //特色
                self: "", //自我介绍
                mixer: "", //昵称
                name: "", //姓名
                sex: "", //性别
                label: [], //配音师列表
                introduce: "", //录音id
                head_portrait: "", //头像
                accent: [], //配音师方言
                alipay_account: ""//支付宝
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            dialogImageUrl: "",
            dialogVisible: false,
            page: 1,
            inputD: "",
            checkList: [], //配音师标签
            localismList: [], //配音师方言标签
            fileList: [],
            // SourceList: "",
        };
    },
    mounted () { },
    components: {
        // VideoSourceDialog,
    },
    methods: {
        //上传视频
        uploadVideo () {
            this.$refs.VideoSourceDialog.onDialog();
        },
        //子视频接收数据参数
        onSourceList (list) {
            // console.log(list);
            this.SourceList = list;
        },
        onyunyingtan () {
            this.yunyingtan = true;
            this.getMixerLabelList();
            this.getMixerAccentList();
        },
        getMixerLabelList () {
            this.$service.post(this.$api.getMixerLabelList, "", (res) => {
                if (res.code == "200") {
                    this.checkList = res.data;
                }
            });
        },
        // 获取方言
        getMixerAccentList () {
            this.$service.post(this.$api.getMixerAccentList, "", (res) => {
                if (res.code == "200") {
                    this.localismList = res.data;
                }
            });
        },
        onShopCheChange (val) {
            this.ShopCheckList = val;
        },
        onlocalismChange (val) {
            // this.localismList = val;
        },
        handleRemoves (file, fileList) {
            // this.dialogImageUrl = "";
        },
        // handlePictureCardPreview(file) {
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        // handlePictureCardPreview(file) {
        //     window.open(
        //         "https://user.erlangcha.com/advances/Advimage?imageurl=" +
        //             this.dialogImageUrl
        //     );
        // },
        // quxiao() {
        //     this.yunyingtan = false;
        // },
        closeDialog () {
            this.form.introduce = "";
            this.form.label = [];
            this.form.accent = [];
            this.form.sex = "";
            this.form.self = "";
            this.form.alipay_account = "";
            this.form.feature = "";
            this.form.head_portrait = [];
            this.dialogImageUrl = "";
            this.$refs.upload.clearFiles();
            // this.$refs.VideoSourceDialog.clear();
            this.$refs.uploadbud.clearFiles();
            // this.SourceList = "";
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao () {
            var param = this.form;
            console.log(param)
            if (param.mixer == "") {
                this.$message({ message: "请输入配音师昵称", type: "warning" });
                return;
            }
            if (param.name == "") {
                this.$message({ message: "请输入配音师姓名", type: "warning" });
                return;
            }
            if (param.head_portrait == "") {
                this.$message({ message: "请上传配音师头像", type: "warning" });
                return;
            }
            if (param.feature == "") {
                this.$message({ message: "请输入配音师特色", type: "warning" });
                return;
            }
            if (param.self == "") {
                this.$message({ message: "请输入配音师简介", type: "warning" });
                return;
            }
            if (param.sex == "") {
                this.$message({ message: "请选择配音师性别", type: "warning" });
                return;
            }
            if (param.label == []) {
                this.$message({ message: "请选择配音师类型", type: "warning" });
                return;
            }
            if (param.accent == []) {
                this.$message({ message: "请选择请选择方言", type: "warning" });
                return;
            }
            if (param.introduce == "") {
                this.$message({ message: "请上传配音师录音", type: "warning" });
                return;
            }
            this.$service.post(this.$api.addMixer, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.yunyingtan = false;
                    this.form.introduce = "";
                    this.form.label = [];
                    this.form.accent = [];
                    this.form.sex = "";
                    this.form.self = "";
                    this.form.alipay_account = "";
                    this.form.feature = "";
                    this.form.head_portrait = [];
                    this.form.mixer = "";
                    this.form.name = "";
                    this.dialogImageUrl = "";
                    this.$refs.upload.clearFiles();
                    this.$refs.uploadbud.clearFiles();
                    this.$parent.ongetTopAgentAccountList();
                }
            });
        },

        //上传文件移出
        onRemoveupload (file, fileList) {
            // console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //头像上传成功
        onSuccessupload (res, file, fileList) {
            this.form.head_portrait = res.data.file_id;
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload (file, fileList) {
            // if (fileList.length > 1) {
            // 	fileList.splice(1, 1);
            // }
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },

        //配音上传成功
        onSuccessbud (res, file, fileList) {
            this.form.introduce = res.data.file_id;
            // console.log(res.data);
        },
        handleRemove (file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview (file) {
            // console.log(file);
        },
        handleExceed (files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove (file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>