import { render, staticRenderFns } from "./AddTeacher.vue?vue&type=template&id=b120df92&scoped=true&"
import script from "./AddTeacher.vue?vue&type=script&lang=js&"
export * from "./AddTeacher.vue?vue&type=script&lang=js&"
import style0 from "./AddTeacher.vue?vue&type=style&index=0&id=b120df92&lang=scss&scoped=true&"
import style1 from "./AddTeacher.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b120df92",
  null
  
)

export default component.exports